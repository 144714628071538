//import {Router} from "react-router-dom";
import "./App.css";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import HomePage from "./pages/Home";
import Buy from "./pages/Buy";
import Meet from "./pages/Meet";
import Profile from "./pages/Profile";
import Wallet from "./pages/Wallet";
import Transact from "./pages/Transact";
import StokvelPage from "./pages/Stokvel";
import Transfer from "./pages/Tranfer";
import React from "react";
import { BrowserRouter as Router, Route, Routes, Switch} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';

import { createTheme } from '@mui/material/styles';
import StokvelList from "./components/Stokvel/StokvelLists/StokvelList";
import StokvelListPage from "./pages/StokvelLists";

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
     // Provide every color token (light, main, dark, and contrastText) when using
     // custom colors for props in Material UI's components.
     // Then you will be able to use it like this: `<Button color="custom">`
     // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
  {/* <HomePage/> */}
  
        <Router>
        <Switch>
          <Route exact path="/"><Login/></Route>
          <Route path="/home"><HomePage/></Route>
          <Route path="/registration"><Registration/></Route>
          <Route path="/buy"><Buy/></Route>
          <Route path="/meet"><Meet/></Route>
          <Route path="/profile"><Profile/></Route>
          <Route path="/stokvel"><StokvelPage/></Route>
          <Route path="/transact"><Transact/></Route>
          <Route path="/wallet"><Wallet/></Route>
          <Route path="/stokvelList"><StokvelListPage/></Route>
          <Route path="/transfer"><Transfer/></Route>

          </Switch>
        </Router>
   
    </ThemeProvider>
  );
}

export default App;
