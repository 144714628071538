import React, { useState, forwardRef } from "react";
import { Typography } from "@mui/material";
import MaterialTable from "material-table";
import Search from "@mui/icons-material/Search";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import ViewColumn from "@mui/icons-material/ViewColumn";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import Burial2 from "./Burial2";
import MediaQuery from "react-responsive";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from "@mui/icons-material/Info";

const tableIcons = {
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Burial = () => {
  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },
    {
      title: "Description",
      field: "description",
      initialEditValue: "initial edit value",
    },
    { title: "Number of Members", field: "members", type: "numeric" },
  ]);

  const [data, setData] = useState([
    {
      name: "Sivuno Properties",
      description:
        " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      members: 10,
    },
    {
      name: "The Gents",
      description:
        " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      members: 7,
    },
    {
      name: "Lubanjiswano Properties",
      description:
        " Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
      members: 25,
    },
  ]);

  return (
    <div>
      <MediaQuery minWidth={600}>
      <div
        style={{
            width: "75%",
            margin: "5rem auto",
            marginTop: "20px",
            backgroundColor: "transparent",
            position: "relative",
            alignItems: "center",
            padding: "25px",
          }}
      >
        <Typography variant="h4" style={{ marginBottom: "2rem", fontWeight:"bold" }}>
          Burial Stokvel
        </Typography>
        <Typography style={{alignContent:"justify"}}>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </Typography>
      </div>
      <div
        style={{
          width: "75%",
          margin: "5rem auto",
          marginTop: "20px",
          backgroundColor: "transparent",
          position: "relative",
          alignItems: "center",
          padding: "25px",
        }}
      >
      <MaterialTable
        title="Registered Grocery Stokvel"
        columns={columns}
        data={data}
        icons={tableIcons}
        actions={[
          {
            icon: GroupAddIcon,
            tooltip: "Join Stokvel",
            //onClick: handleOpen, //modal for paying money
          },
          {
            icon: InfoIcon,
            tooltip: "Stokvel Information",
            //onClick: handleOpen,
          },
        ]}
      />
      </div>
      </MediaQuery>
      <MediaQuery maxWidth={600}>
        {<Burial2/>}
      </MediaQuery>
    </div>
  );
};
export default Burial;
