import React from "react";
import Profile from "../components/Profile";
import Navbar from "../components/Navigation/NavBar";


const ProfilePage =()=>{
return(
    <div style={{ backgroundColor: "#cccccc", width: "100%", height: "100vh" }}>
    <Navbar/>
    <Profile/>
    </div>
  
)
}
export default ProfilePage;