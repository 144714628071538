import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Icon,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import classes from "./Home.module.css";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import SyncAltRoundedIcon from "@mui/icons-material/SyncAltRounded";
import Banner from "./Banner";
import MediaQuery from "react-responsive";
import Home2 from "./Home2";
import Stokvel from "../Stokvel/Stokvel";

const Home = () => {
  return (
    <>
<MediaQuery minWidth={600}>
    <div className={classes.container}>
    {<Stokvel/>}
      {/* <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4} style={{position:"absolute"}}>
          <Grid item md={1}>
            <Button
              variant="contained"
              style={{
                backgroundImage:
                  "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
              }}
            >
              {<PersonRoundedIcon fontSize="large" />}
            </Button>
            <Typography style={{ textAlign: "center" }}>Profile</Typography>
          </Grid>
          <Grid item md={1}>
            <Button
              variant="contained"
              href="/stokvel"
              style={{
                backgroundImage:
                  "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
              }}
            >
              {<CurrencyExchangeRoundedIcon fontSize="large" />}
            </Button>
            <Typography style={{ textAlign: "center" }}>Stokvel</Typography>
          </Grid>
          <Grid item md={1}>
            <Button
              variant="contained"
             href="/wallet"
              style={{
                backgroundImage:
                  "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
              }}
            >
              {<AccountBalanceWalletRoundedIcon fontSize="large" />}
            </Button>
            <Typography style={{ textAlign: "center" }}>Wallet</Typography>
          </Grid>
          <Grid item md={1}>
            <Button
              variant="contained"
              style={{
                backgroundImage:
                  "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
              }}
            >
              {<ShoppingCartRoundedIcon fontSize="large" />}
            </Button>
            <Typography style={{ textAlign: "center" }}>Buy</Typography>
          </Grid>

          <Grid item md={1}>
            <Button
              variant="contained"
              style={{
                backgroundImage:
                  "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
              }}
            >
              {<SyncAltRoundedIcon fontSize="large" />}
            </Button>
            <Typography style={{ textAlign: "center" }}>Transact</Typography>
          </Grid>
          <Grid item md={1}>
            <Button
              variant="contained"
              style={{
                backgroundImage:
                  "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
              }}
            >
              {<GroupsRoundedIcon fontSize="large" />}
            </Button>
            <Typography style={{ textAlign: "center" }}>Meet</Typography>
          </Grid>
        </Grid>
      </Box> */}
    </div>
    </MediaQuery>
    <MediaQuery maxWidth={600}>{<Home2/>}</MediaQuery>
    </>
  );
};
export default Home;
