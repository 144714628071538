import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Avatar,
  Stack,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Divider,
} from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SettingsRoundIcon from "@mui/icons-material/SettingsRounded";
import { DriveEtaSharp } from "@mui/icons-material";
import avatar from "./images/avatar.jpg";

const Profile2 = () => {
  return (
    <div style={{height:"100%",padding:"20px"}}>
      <Typography variant="h5" style={{ textAlign: "center" }}>
        Profile
      </Typography>
      <div
        style={{
          width: "75%",
          padding: "20px",
          position: "relative",
          backgroundColor: "transparent",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={3}>
          <Avatar alt="Avatar" src={avatar}/>
          </Grid>
          <Grid item md={6}>
            <Typography>John Smith</Typography>
            <Typography>john@stokvel.com</Typography>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          padding: "15px",
          position: "relative",
          backgroundColor: "transparent",
          marginTop: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
             
            >
              Settings
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
             
            >
              Password Reset
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              
            >
              Personal Information
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              
            >
              Contact Information
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              
            >
              Upload Documents
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default Profile2;
