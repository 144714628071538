import React from "react";
import Navbar from "../components/Navigation/NavBar";
import Transfer from "../components/Wallet/Transfer";

const Transfers = () => {

    return(
        <>
        <Navbar/>
        <Transfer/>
        </>
    )
}
export default Transfers