import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Icon,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import fnb from "../images/fnb.png";
import momo from "../images/momo.png";
import e_mali from "../images/e_mali.png";
import swazibank from "../images/swazibank.png";
import standard from "../images/standard.jpg";
import nedbank from "../images/nedbank.png";

const Tranfer = () => {
  return (
    <div style={{ padding: "35px", marginTop:"5px"}}>
      <div>
        <Typography >Transfer To:</Typography>
      </div>

      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>

            <Grid item md={4}>
              <IconButton style={{height:"20px", weight:"20px"}}>
                <img src={fnb} />
              </IconButton>
            </Grid>

            <Grid item xs={4}>
              <IconButton style={{height:"20px", weight:"20px"}}>
                <img src={momo} />
              </IconButton>
            </Grid>

            <Grid item xs={6}>
              <IconButton style={{height:"20px", weight:"20px"}}>
                <img src={nedbank} />
              </IconButton>
            </Grid>

            <Grid item xs={6}>
              <IconButton>
                <img src={swazibank} />
              </IconButton>
            </Grid>

            <Grid item xs={6}>
              <IconButton>
                <img src={e_mali} />
              </IconButton>
            </Grid>

            <Grid item xs={6}>
              <IconButton>
                <img src={standard} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
export default Tranfer;
