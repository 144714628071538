import React from "react";
import Banner from "../components/Home/Banner";
import LoginForm from "../components/Login/LoginForm";
import Navbar from "../components/Navigation/NavBar";


const Login = () => {
  return (
    <div style={{ backgroundColor: "#cccccc", width: "100%", height: "100vh" }}>
     
      <LoginForm />
    </div>
  );
};
export default Login;
