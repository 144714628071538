import React from 'react';
import GoogleLogin from 'react-google-login';
import googleIcon from '../assets/googleIcon.jpg';

const GoogleSignIn = ()=>{

  const responseGoogle = (response) => {
    // FIXME: USE action to send google reponse to endpoint
    if (response) {
      // const payload = {
      //   ip: ip,
      //   third_party: "Google",
      //   response_payload: response,
      //   t_c_accepted_at: dateFormat(new Date(), "yyyy-mm-dd").toString(),
      //   t_c_accepted_version: "1",
      };
      //googleAuthAPI(payload);
      
  };

  return(
<GoogleLogin
    clientId={'googleAuth.clientID'}
    onSuccess={responseGoogle}
    redirectUri={'googleAuth.redirectURI'}
    cookiePolicy={"single_host_origin"}
    scope={
    "profile email https://www.googleapis.com/auth/user.phonenumbers.read"
    }
    className="my-google-button-class google-button"
    isSignedIn={false}
    icon={false}
>
    <div style={{width: "100", alignContent: "center"}}>
    <img
        alt="facebook"
        src={googleIcon}
        style={{ alignContent: "center" }}
    />
    Sign in with Google
    </div>
</GoogleLogin>
  )
  }
export default GoogleSignIn