import React from "react";
import Home from "../components/Home/Home";
import Navbar from "../components/Navigation/NavBar";
import Carousel from "../components/Home/Carousel";

const HomePage = () => {
  return (
    <div style={{ backgroundColor: "#cccccc", width: "100%", height: "100vh" }}>
      <Navbar />
      {/* <Carousel /> */}
      <Home />
    </div>
  );
};
export default HomePage;
