import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Divider,
  Avatar,
} from "@mui/material";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SettingsRoundIcon from "@mui/icons-material/SettingsRounded";
import { DriveEtaSharp } from "@mui/icons-material";
import Profile2 from "./Profile2";
import MediaQuery from "react-responsive";
import { deepOrange, deepPurple } from "@mui/material/colors";

const Profile = () => {
  return (
    <div>
      <MediaQuery minWidth={600}>
        <div
          style={{
            width: "75%",
            padding: "20px",
            left: "40%",
            position: "relative",
            backgroundColor: "transparent",
            marginTop: "3rem",
            marginBottom: "1rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Avatar sx={{ bgcolor: deepPurple[700] }}>N</Avatar>
              <Grid item md={5}>
                <Typography>John Smith</Typography>
                <Typography>john@stokvel.com</Typography>
              </Grid>
            </Grid>

            {/* <Grid item md={2} style={{left:"75%"}}>
            <Button >{<SettingsRoundIcon fontSize="large"/>}</Button>
          </Grid>
       */}
          </Grid>
        </div>

        <div
          style={{
            width: "50%",
            padding: "20px",
            left: "25%",
            position: "relative",
            backgroundColor: "transparent",
            marginTop: "1rem",
          }}
        >
          <Grid container spacing={2}>
            <Grid item md={10}>
              <Button variant="contained" fullWidth>
                Password Reset
              </Button>
            </Grid>
            <Grid item md={10}>
              <Button variant="contained" fullWidth>
                Personal Information
              </Button>
            </Grid>
            <Grid item md={10}>
              <Button variant="contained" fullWidth>
                Contact Information
              </Button>
            </Grid>
            <Grid item md={10}>
              <Button variant="contained" fullWidth>
                Upload Documents
              </Button>
            </Grid>
          </Grid>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={600}>{<Profile2 />}</MediaQuery>
    </div>
  );
};
export default Profile;
