import React from "react";
import classes from "./SignUp.module.css";
import {
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import GoogleSignIn from "./GoogleSignIn";
import { useFormik } from "formik";
import * as Yup from "yup";
import SendSharpIcon from "@mui/icons-material/SendSharp";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Enter Valid Email")
    .required("Required"),
  userName: Yup.string().required("Required"),
  password: Yup.string()
    .min(6, "Too Short!")
    .max(20, "Too Long!")
    .required("Required"),
    confirmPassword: Yup.string().when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password Does Not Match")
    })
});

const SignUp = ({ handleNext }) => {

  const [showPasswordValue, setShowPasswordValue] = useState({
    showPassword: false,
  });
  const [showConfirmPasswordValue, setShowConfirmPassValue] = useState({
    showConfirmPassword: false,
  });

  const handleClickShowPassword = () => {
    setShowPasswordValue({
      showPassword: !showPasswordValue.showPassword,
    });
  };

  const handleClickConfirmPassword = () => {
    setShowConfirmPassValue({
      showConfirmPassword: !showConfirmPasswordValue.showConfirmPassword,
    });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      userName: "",
      password: "",
      confirmPassword: ""
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      handleNext();
    },
  });

  return (
    <div className={classes.mainContainer}>
      <Typography
        variant="h5"
        style={{
          textAlign: "center",
          color: "black",
          paddingTop: "20px",
          paddingBottom: "15px",
        }}
      >
        SignUp
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <item>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  id="email"
                  label="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </item>
            </Grid>

            <Grid item md={6}>
              <item>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  label="UserName"
                  id="userName"
                  name="userName"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.userName && Boolean(formik.errors.userName)
                  }
                  helperText={formik.touched.userName && formik.errors.userName}
                />
              </item>
            </Grid>

            <Grid item md={6}>
              <item>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="password">Password</InputLabel>
                  <OutlinedInput
                    type={showPasswordValue.showPassword ? "text" : "password"}
                    labelWidth={75}
                    label="Password"
                    labelId="password"
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPasswordValue.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </item>
            </Grid>

            <Grid item md={6}>
              <item>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  fullWidth
                >
                  <InputLabel id="confirmPassword">Confirm Password</InputLabel>
                  <OutlinedInput
                    type={
                      showConfirmPasswordValue.showConfirmPassword
                        ? "text"
                        : "password"
                    }
                    labelWidth={75}
                    id="confirmPassword"
                    labelId="confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickConfirmPassword}
                        >
                          {showConfirmPasswordValue.showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </item>
            </Grid>
            <Grid item md={6}>
              <item>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  style={{ left: "50%" }}
                  endIcon={<SendSharpIcon />}
                >
                  PROCEED
                </Button>
              </item>
            </Grid>
          </Grid>
        </form>
        <p
          style={{
            justifyContent: "center",
            marginLeft: "40%",
            marginBottom: "20px",
          }}
        >
          {<GoogleSignIn />}
        </p>
      </Box>
    </div>
  );
};

export default SignUp;
