import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import {
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import classes from "./BankDetails.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import SendSharpIcon from "@mui/icons-material/SendSharp";

const validationSchema = Yup.object({
  bank: Yup.string().required("Required"),
  branchCode: Yup.string()
    .required("Required")
    .min(1, "Too Short ")
    .max(6, "Too Long!"),
  accountNumber: Yup.string()
    .min(2, "Too Short!")
    .max(12, "Too Long!")
    .required("Required"),
  phoneNumber: Yup.string()
    .min(2, "Too Short!")
    .max(11, "Too Long!")
    .required("Required"),
  optionalPhoneNumber: Yup.string()
    .min(2, "Too Short!")
    .max(11, "Too Long!"),
  // phonePurpose1: Yup.array()
  // .min(1, "Atleast two Box should be checked")
  // .required("Choose atleast 2 Phone Number"),
  // phonePurpose2: Yup.array()
  //   .min(1, "Atleast one Box should be checked"),
});

const BankDetails = ({handleNext}) => {
  //Checkboxes
  const [state, setState] = useState({
    momo: false,
    wallet: false,
    calls: false,
    whatsApp: false,
    momo1: false,
    wallet1: false,
    calls1: false,
    whatsApp1: false,
  });

  const formik = useFormik({
    initialValues: {
      bank: "",
      branchCode: "",
      accountNumber: "",
      phoneNumber: "",
      optionalPhoneNumber: "",

    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      handleNext();
    },
  });

  const handleCheckboxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div className={classes.mainContainer}>
      <Typography
        variant="h5"
        style={{
          textAlign: "center",
          color: "black",
          paddingTop: "20px",
          paddingBottom: "15px",
        }}
      >
        Banking BankDetails
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
          <Grid container spacing={1.5}>
            <Grid item sx={12} md={4}>
              <item>
                <FormControl fullWidth>
                  <InputLabel id="bank">Bank</InputLabel>
                  <Select
                    id="bank"
                    labelId="bank"
                    variant="outlined"
                    label="Bank"
                    name="bank"
                    value={formik.values.bank}
                    onChange={formik.handleChange}
                    error={formik.touched.bank && Boolean(formik.errors.bank)}
                    helperText={formik.touched.bank && formik.errors.bank}
                  >
                    <MenuItem value={"fnb"}>FNB</MenuItem>
                    <MenuItem value={"nedbank"}>NEDBANK</MenuItem>
                    <MenuItem value={"standardBank"}>STANDARD BANK</MenuItem>
                    <MenuItem value={"eswatini"}>ESWATINI BANK</MenuItem>
                  </Select>
                </FormControl>
              </item>
            </Grid>

            <Grid item xs={12} md={3}>
              <item>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  label="Branch Code"
                  id="branchCode"
                  name="branchCode"
                  value={formik.values.branchCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.branchCode &&
                    Boolean(formik.errors.branchCode)
                  }
                  helperText={
                    formik.touched.branchCode && formik.errors.branchCode
                  }
                />
              </item>
            </Grid>

            <Grid item xs={12} md={5}>
              <item>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  label="Account Number"
                  name="accountNumber"
                  id="accountNumber"
                  value={formik.values.accountNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.accountNumber &&
                    Boolean(formik.errors.accountNumber)
                  }
                  helperText={
                    formik.touched.accountNumber && formik.errors.accountNumber
                  }
                />
              </item>
            </Grid>

            <Grid item xs={12} md={6}>
              <item>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  label="Phone Number"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
                <FormGroup row
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.momo}
                        onChange={handleCheckboxChange}
                        name="momo"
                      />
                    }
                    label="MOMO"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.wallet}
                        onChange={handleCheckboxChange}
                        name="wallet"
                      />
                    }
                    label="Wallet"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.calls}
                        onChange={handleCheckboxChange}
                        name="calls"
                      />
                    }
                    label="Calls"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.whatsApp}
                        onChange={handleCheckboxChange}
                        name="whatsApp"
                      />
                    }
                    label="WhatsApp"
                  />
                </FormGroup>
              </item>
            </Grid>

            <Grid item xs={12} md={6}>
              <item>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  label="Phone Number (optional)"
                  name="optionalPhoneNumber"
                  id="optionalPhoneNumber"
                  value={formik.values.optionalPhoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.optionalPhoneNumber &&
                    Boolean(formik.errors.optionalPhoneNumber)
                  }
                  helperText={
                    formik.touched.accountNumber &&
                    formik.errors.optionalPhoneNumber
                  }
                />
                <FormGroup row id="phonePurpose2" name="phonePurpose2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.momo1}
                        onChange={handleCheckboxChange}
                        name="momo1"
                      />
                    }
                    label="MOMO"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.wallet1}
                        onChange={handleCheckboxChange}
                        name="wallet1"
                      />
                    }
                    label="Wallet"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.calls1}
                        onChange={handleCheckboxChange}
                        name="calls1"
                      />
                    }
                    label="Calls"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.whatsApp1}
                        onChange={handleCheckboxChange}
                        name="whatsApp1"
                      />
                    }
                    label="WhatsApp"
                  />
                </FormGroup>
              </item>
            </Grid>
            <Grid item md={6}>
              <item>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  style={{ left: "50%" }}
                  endIcon={<SendSharpIcon />}
                >
                  PROCEED
                </Button>
              </item>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default BankDetails;
