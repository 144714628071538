import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import SyncAltIcon from "@mui/icons-material/SyncAltRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import HomeIcon from "@mui/icons-material/Home";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";

export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <Stack direction="column" spacing={0.5}>
            <Tooltip title="See Profile">
              <ListItemButton href="/profile">
                <Avatar sx={{ bgcolor: deepOrange[700] }}>N</Avatar>
              </ListItemButton>
            </Tooltip>
            <Typography style={{ paddingLeft: "15px" }}>
              {" "}
              John Smith{" "}
            </Typography>
            <Typography style={{ paddingLeft: "15px" }}>
              john@stokvel.com
            </Typography>
          </Stack>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem disablePadding>
          <ListItemButton href="/home">
            <ListItemIcon>{<HomeIcon />}</ListItemIcon>
            Home{" "}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton href="/stokvel">
            <ListItemIcon>{<CurrencyExchangeIcon />}</ListItemIcon>
            Stokvel{" "}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton href="/wallet">
            <ListItemIcon>{<AccountBalanceWalletIcon />}</ListItemIcon>
            Wallet{" "}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton href="/buy">
            <ListItemIcon>{<ShoppingCartIcon />}</ListItemIcon>
            Buy{" "}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton href="/transact">
            <ListItemIcon>{<SyncAltIcon />}</ListItemIcon>
            Transact{" "}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton href="/meet">
            <ListItemIcon>{<GroupsIcon />}</ListItemIcon>
            Meet{" "}
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton href="/">
            <ListItemIcon>{<LoginOutlinedIcon/>}</ListItemIcon> 
            Logout{" "}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {<MenuRoundedIcon fontSize="large"/>}
            <Typography variant="h6" style={{fontWeight:"bold"}}>STOKVEL</Typography>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
