import React from "react";
import Navbar from "../components/Navigation/NavBar";
import Wallet from "../components/Wallet/Wallet";
import underConstruction from "../components/images/underConstruction.png";

const Wallets =()=>{
return(
    <div style={{ backgroundColor: "#cccccc", width: "100%", height: "100vh" }}>
    <Navbar/>
    <Wallet/>
   </div>
)
}
export default Wallets;