import React, { useState, forwardRef } from "react";
import MaterialTable from "material-table";
import InfoIcon from "@mui/icons-material/Info";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const OutstandingBalance = () => {
  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },
    {
      title: "Outstanding Balance Owed (E)",
      field: "outstanding",
    },
    {
      title: "Balance Paid (E)",
      field: "paid",
    },
  ]);

  const [data, setData] = useState([
    { name: "Masizubele Etulu", outstanding: 2000, paid: 1000 },
    { name: "Sanlam Ladies", outstanding: 300, paid: 4000 },
    { name: "Team_Zee", outstanding: 100, paid: 2000 },
  ]);

  return (
    <MaterialTable
    title="Summary"
    icons={tableIcons}
      columns={columns}
      data={data}
      actions={[
        {
          icon: PriceCheckIcon,
          tooltip: "Pay Loan",
          //onClick: handleOpen, //modal for paying money
        },
        {
          icon: InfoIcon,
          tooltip: "Debt Info",
          //onClick: handleOpen,
        },
      ]}
    />
  );
};
export default OutstandingBalance;
