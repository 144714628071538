import React from "react";
import Stokvel from "../components/Stokvel/Stokvel";
import Navbar from "../components/Navigation/NavBar";

const StokvelPage =()=>{
return(
    <div style={{ backgroundColor: "#cccccc", width: "100%", height: "100vh" }}>
    <Navbar/>
    <Stokvel/>
    </div> 
)
}
export default StokvelPage;