import React, { useState } from "react";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import classes from "./RegistrationForms.module.css";
//Steps Imports for Big Screens
import PersonalInformation from "./PersonalInformation";
import SignUp from "./SignUp";
import BankDetails from "./BankDetails";
import TsAndCs from "./TsAndCs";
import Upload from "./Upload";
//Steps Imports for Small Screen
import SignUp2 from "./SignUp2";
import PersonalInformation2 from "./PersonalInformation2";
import BankDetails2 from "./BankDetails2";
//Icons
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
//media queries
import MediaQuery from "react-responsive";

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        // "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
        "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

// vertical stepper
const ColorlibConnector2 = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        // "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
        "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 20,
    width: 3,
    flexDirection: "column",
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,200,77) 50%,rgb(70,110,70) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,200,77) 50%,rgb(70,110,70) 100%)",
  }),
}));
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <ExitToAppOutlinedIcon />,
    2: <PersonAddAlt1OutlinedIcon />,
    3: <AccountBalanceOutlinedIcon />,
    4: <FileUploadOutlinedIcon />,
    5: <AssignmentTurnedInOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const RegistrationForms = () => {
  //react hooks to manage flow across the stepper on change
  const [activeStep, setActiveState] = useState(0);
  //Button Next handler
  const handleNext = () => {
    setActiveState((prevActiveState) => prevActiveState + 1);
  };

  //list of steps
  function RegSteps() {
    return [
      "Signup Information",
      "Personal Information",
      "Bank Details",
      "Upload Supporting Documents",
      "Ts & Cs",
    ];
  }

  //switch statement for the step list
  function RegStepsContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <SignUp handleNext={handleNext} />;

      case 1:
        return <PersonalInformation handleNext={handleNext} />;

      case 2:
        return <BankDetails handleNext={handleNext} />;

      case 3:
        return <Upload handleNext={handleNext} />;

      case 4:
        return <TsAndCs handleNext={handleNext} />;

      default:
        return "Please Signup First";
    }
  }

  function VRegStepsContent(vStepIndex) {
    switch (vStepIndex) {
      case 0:
        return <SignUp2 handleNext={handleNext} />;
      case 1:
        return <PersonalInformation2 handleNext={handleNext} />;
      case 2:
        return <BankDetails2 handleNext={handleNext} />;
      default:
        return "Register First!";
    }
  }

  //object to pass stepps in jsx
  const steps = RegSteps();
  const vsteps = RegSteps();

  return (
    <>
    <div className={classes.root}>
      {/* Render on Big Screen */}
      <MediaQuery minWidth={600}>
        <Stepper
          style={{ marginTop: "3rems" }}
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            "You are Successfully Registered!"
          </p>
        ) : (
          <>{RegStepsContent(activeStep)}</>
        )}
      </MediaQuery>
</div>
<div style={{padding:"5px"}}>
      {/* Render on Small Screens */}
      <MediaQuery maxWidth={600}>
        <Stepper
          orientation="vertical"
          style={{ marginTop: "1rems" }}
          //alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector2 />}
        >
          {vsteps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
              <StepContent>
                {activeStep === steps.length ? (
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      paddingTop: "50px",
                      paddingBottom: "50px",
                    }}
                  >
                    "You are Successfully Registered!"
                  </p>
                ) : (
                  <>{VRegStepsContent(activeStep)}</>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </MediaQuery>
      </div>
    </>
  );
};

export default RegistrationForms;
