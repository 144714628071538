import {
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import React from "react";
import classes from "./Login.module.css";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import Link from "@mui/material/Link";
import LoginForm2 from "./LoginForm2";
import MediaQuery from "react-responsive";

const validationSchema = Yup.object({
  userName: Yup.string().required("Username Required"),
  Pin: Yup.string()
    .required("Pin Required")
    .min(3, "Must be 4 charecters")
    .max(4, "Too Long"),
});

export default function LoginForm({ handleNext }) {
  const formik = useFormik({
    initialValues: {
      userName: "",
      pin: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      handleNext();
    },
  });

  return (
    <>
    <MediaQuery minWidth={600}>
    <div className={classes.root}>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
        <Typography
        variant="h3"
        style={{
          textAlign: "center",
          color: "black",
          marginBottom: "2rem",
          fontWeight: "bold",
        }}
      >
        Login
      </Typography>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TextField
                variant="outlined"
                label="USERNAME"
                name="userName"
                id="userName"
                fullWidth
                value={formik.values.userName}
                onChange={formik.handleChange}
                error={
                  formik.touched.userName && Boolean(formik.errors.userName)
                }
                helperText={formik.touched.userName && formik.errors.userName}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                varient="outlined"
                label="PIN"
                name="pin"
                id="pin"
                fullWidth
                value={formik.values.pin}
                onChange={formik.handleChange}
                error={formik.touched.pin && Boolean(formik.errors.pin)}
                helperText={formik.touched.pin && formik.errors.pin}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Button
                variant="contained"
                endIcon={<LoginOutlinedIcon />}
                fullWidth
                type="submit"
                href="/home"
              >
                LOGIN
              </Button>
            </Grid>

            <Grid item md={12} xs={12}>
              <Typography
                variant="h5"
                style={{ marginTop: "2rem", marginBottom: "10rem" }}
              >
                Dont have an Account?{" "}
                {
                  <Link href="/registration" underline="hover">
                    SignUp Here
                  </Link>
                }{" "}
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
    </MediaQuery>
    <MediaQuery maxWidth={600}>
      {<LoginForm2/>}
    </MediaQuery>
    </>
  );
}
