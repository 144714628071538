import { Carousel } from "react-carousel-minimal";
import React from "react";
import stokvel1 from "../images/stokvel1.jpg";
import stokvel2 from "../images/stokvel2.jpeg";
import stokvel4 from "../images/stokvel4.jpg";
import savings from "../images/savings.jpg";
import MediaQuery from "react-responsive";
import ImageCorousel2 from "./Carousel2";

function ImageCorousel() {
  const data = [
    {
      image: [stokvel1],
      caption: "Stokvel",
    },
    {
      image: [stokvel2],
      caption: "Invest",
    },
    {
      image: [savings],
      caption: "Property Stokvel",
    },
  ];
  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <div>
      <MediaQuery minWidth={600}>
        <Carousel
          data={data}
          time={5000}
          width="100%"
          height="500px"
          captionStyle={captionStyle}
          radius="10px"
          captionPosition="bottom"
          automatic={true}
          dots={true}
          pauseIconColor="black"
          pauseIconSize="40px"
          slideBackgroundColor="darkgrey"
          slideImageFit="cover"
          style={{
            textAlign: "center",
            maxWidth: "100%",
            maxHeight: "500px",
            margin: "40px auto",
            marginBottom: "4rem",
            overlay: "black",
          }}
        />
      </MediaQuery>
      <MediaQuery maxWidth={600}>{<ImageCorousel2/>}</MediaQuery>
    </div>
  );
}

export default ImageCorousel;
