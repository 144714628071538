import React, { useState, forwardRef } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Select,
  MenuItem,
  Modal,
  Link,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useFormik } from "formik";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MaterialTable from "material-table";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import InfoIcon from "@mui/icons-material/Info";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MediaQuery from "react-responsive";
import Stokvel2 from "./Stokvel2";

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Stokvel = () => {
  //Modal for paying Money
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [stokvelType, setStokvelType] = useState();
  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },
    {
      title: "Type",
      field: "type",
      initialEditValue: "initial edit value",
      lookup: { 1: "Property", 2: "Grocery", 3: "Savings", 4: "Burial" },

    },
    { title: "Total Contributions(E)", field: "cbalance", type: "numeric"},
    { title: "Stokvel Balance(E)", field: "balance", type: "numeric" },
    
  ]);

  const [data, setData] = useState([
    { name: "Masizubele Etulu", type: 3, cbalance: 200, balance: 1900 },
    { name: "Sanlam Ladies", type: 2, cbalance: 1100, balance: 2000 },
    { name: "Team_Zee", type: 1, cbalance: 2000, balance: 3000000 },
  ]);

  return (
    <div style={{height: '100vh'}}>
      <MediaQuery minWidth={600}>
        <div
          style={{
            width: "75%",
            margin: "10rem auto",
            marginTop: "50px",
            backgroundColor: "transparent",
            position: "relative",
            alignItems: "center",
            padding: "25px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Button
                variant="contained"
                  fullWidth
                  style={{
                    
                    marginBottom: "3rem",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  href="/stokvelList"
                >
                  Join Stokvel
                </Button>
              </Grid>
            </Grid>
          </Box>

          <MaterialTable
            title="Current Stokvels "
            icons={tableIcons}
            columns={columns}
            data={data}
            actions={[
              {
                icon: AddBox,
                tooltip: "Add Stokvel",
                isFreeAction: true,
                onClick: handleOpen, //direct to a create stokvel page
              },
              {
                icon: PriceCheckIcon,
                tooltip: "Pay Money",
                onClick: handleOpen, //modal for paying money
              },
              {
                icon: RequestQuoteIcon,
                tooltip: "Request Money",
                onClick: handleOpen,
              },
              {
                icon: InfoIcon,
                tooltip: "Stokvel Info",
                onClick: handleOpen,
              },
            ]}
          />

          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                Text in a modal
              </Typography>
              <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>
            </Box>
          </Modal>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={600}>{<Stokvel2 />}</MediaQuery>
    </div>
  );
};
export default Stokvel;
