
import React, { useEffect, useState } from "react";
import { Typography, Button, Grid, Box } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import SendSharpIcon from "@mui/icons-material/SendSharp";

const Upload = ({ handleNext }) => {
  const [files, setFiles] = useState(null);

  function handleChange(files) {
    // console.log(files);
    setFiles(files);
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography>
              Upload I.D.
            </Typography>
            {/* <Button onClick={this.handleChange.bind(this)}>Add ID/Passport Copy</Button> */}
            <DropzoneArea
              // open={this.state.open}
              // onSave={this.handleSave.bind(this)}
              // acceptedFiles={["file/pdf"]}
              // showPreviews={true}
              maxFileSize={5000000}
              // onClose={handleClose.bind(this)}
              onChange={handleChange}

            />
          </Grid>
          <Grid item md={6}>
            <Typography>
              Upload Proof Of Residence
            </Typography>
            {/* <Button onClick={this.handleChange.bind(this)}>Add ID/Passport Copy</Button> */}
            <DropzoneArea
              // open={this.state.open}
              // onSave={this.handleSave.bind(this)}
              // acceptedFiles={["file/pdf"]}
              // showPreviews={true}
              maxFileSize={5000000}
              // onClose={handleClose.bind(this)}
              onChange={handleChange}

            />
          </Grid>

          <Grid item md={6}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              style={{ left: "50%" }}
              onClick={handleNext}
              endIcon={<SendSharpIcon />}
            >
              PROCEED
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Upload;
