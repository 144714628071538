import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Select,
  MenuItem,
} from "@mui/material";
import classes from "./PersonalInformation.module.css";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//using formik validatiom
import { useFormik } from "formik";
import * as Yup from "yup";
//icon buttons
import SendSharpIcon from "@mui/icons-material/SendSharp";


const validationSchema = Yup.object({
  id_PassportNumber: Yup.string()
    .min(2, "Too Short!")
    .max(13, "Too Long!")
    .required("Required"),
  nationality: Yup.string().required("Required"),
  f_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  surname: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  gender: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  dob: Yup.string().required("Required"),
});

const PersonalInformation2 = ({handleNext}) => {
  const formik = useFormik({
    initialValues: {
      id_PassportNumber: "",
      nationality: "",
      f_name: "",
      surname: "",
      dob: new Date(),
      gender: "",
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      handleNext()
    },
  })

  return (
    <div className={classes.mainContainer}>
      <Typography
        variant="h5"
        style={{
          textAlign: "center",
          color: "black",
          paddingTop: "20px",
          paddingBottom: "15",
        }}
      >
        Personal Information
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <form className={classes.formContainer} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="id_PassportNumber"
                  name="id_PassportNumber"
                  className={classes.TextField}
                  value={formik.values.id_PassportNumber}
                  onChange={formik.handleChange}
                  variant="outlined"
                  label="ID / Passport Number"
                  error={
                    formik.touched.id_PassportNumber &&
                    Boolean(formik.errors.id_PassportNumber)
                  }
                  helperText={
                    formik.touched.id_PassportNumber &&
                    formik.errors.id_PassportNumber
                  }
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="nationality">Nationality</InputLabel>
                  <Select
                    id="nationality"
                    name="nationality"
                    labelId="nationality"
                    variant="outlined"
                    label="Nationality"
                    value={formik.values.nationality}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nationality &&
                      Boolean(formik.errors.nationality)
                    }
                    helperText={
                      formik.touched.nationality && formik.errors.nationality
                    }
                  >
                    <MenuItem value={"swazi"}>Swazi</MenuItem>
                    <MenuItem value={"southAfrican"}>South African</MenuItem>
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  label="Name"
                  id="f_name"
                  name="f_name"
                  value={formik.values.f_name}
                  onChange={formik.handleChange}
                  error={formik.touched.f_name && Boolean(formik.errors.f_name)}
                  helperText={formik.touched.f_name && formik.errors.f_name}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                  fullWidth
                  className={classes.textField}
                  variant="outlined"
                  label="Surname"
                  name="surname"
                  id="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.surname && Boolean(formik.errors.surname)
                  }
                  helperText={formik.touched.surname && formik.errors.surname}
                />
            </Grid>

            <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Date of Birth"
                    id="dob"
                    name="dob"
                    fullWidth
                    value={formik.values.dob}
                    onChange={dob => formik.setFieldValue('dob', dob)}
                    renderInput={(params) => <TextField {...params} />}
                    error={
                      formik.touched.dob && Boolean(formik.errors.dob)
                    }
                    helperText={formik.touched.dob && formik.errors.dob}
                  />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth style={{ marginBottom: "1rem" }}>
                  <InputLabel id="gender">Gender</InputLabel>
                  <Select
                    id="gender"
                    name="gender"
                    labelId="gender"
                    variant="outlined"
                    label="Gender"
                    fullWidth
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                    helperText={formik.touched.gender && formik.errors.gender}
                  >
                    <MenuItem value={"female"}>Female</MenuItem>
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"prefer not to say"}>Prefer not to say</MenuItem>
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>  
                <Button
                  
                  variant="contained"
                  fullWidth
                  type="submit"
                  endIcon={<SendSharpIcon />}
                >
                  PROCEED
                </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};
//};
export default PersonalInformation2;
//ReactDOM.render(<WithMaterialUI/>, document.getElementById('root'));
