import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Modal,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Divider,
  Avatar,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import OutstandingBalance from "./OutstandingBalance";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Wallet2 = () => {

  // View Outstanding Balances
  const [open, setOpen] = React.useState(false);

  const handleClickOpenBalance = () => {
    setOpen(true);
  };
  const handleCloseBalance = () => {
    setOpen(false);
  };


  return (
    <div style={{ padding: "35px", marginTop:"5px"} }>
      <div style={{padding:"15%"}}>
        <Typography variant="h4" style={{ fontWeight: "bold", marginBottom:"5px"}}>
          BALANCE
        </Typography>
        <Typography variant="h4" style={{ fontWeight: "bold", marginBottom:"5px"}}>
        E 1000.00
        </Typography>
      </div>

      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<VisibilityIcon fontSize="Large" />}
              onClick={handleClickOpenBalance}
              
            >
              Loans
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<MoveDownIcon fontSize="large" />}
              
            >
              Transfer
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              startIcon={<ShoppingCartIcon fontSize="large" />}
              
            >
              Buy
            </Button>
          </Grid>
        </Grid>
        </div>
    
      <BootstrapDialog
        onClose={handleCloseBalance}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{width:"100%"}}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleCloseBalance}>
          Current Outstanding Balances
        </BootstrapDialogTitle>
        <DialogContent dividers>
      {<OutstandingBalance/>}
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={handleCloseBalance}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
export default Wallet2;
