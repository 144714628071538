import React from "react";
import Navbar from "../components/Navigation/NavBar";
import underConstruction from "../components/images/underConstruction.png";

const Buy =()=>{
return(
   <div style={{ backgroundColor: "#cccccc", width: "100%", height: "100vh" }}>
    <Navbar/>
    <div style={{marginTop:"1rem", padding:"20px"}}>
    <img src={underConstruction} style={{margin: "0 25%"}} />
    </div>
   </div>
)
}
export default Buy