import React, { useState, forwardRef } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Select,
  MenuItem,
  Modal,
  Link,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { useFormik } from "formik";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import MaterialTable from "material-table";
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import InfoIcon from "@mui/icons-material/Info";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const Stokvel2 = () => {
  //Modal for paying Money
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const style = {
    position: "absolute",
    top: "50%",
    padding:"10px",
    left:"15%",
    // transform: "translate(100%, 100%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    //boxShadow: 24,
    p: 4,
  };

  const [stokvelType, setStokvelType] = useState();
  const [columns, setColumns] = useState([
    { title: "Name", field: "name" },
    {
      title: "Type",
      field: "type",
      initialEditValue: "initial edit value",
      lookup: { 1: "Property", 2: "Grocery", 3: "Savings", 4: "Burial" },
    },
    { title: "Balance (E)", field: "balance", type: "numeric" },
  ]);

  const [data, setData] = useState([
    { name: "Masizubele Etulu", type: 3, balance: 1900 },
    { name: "Sanlam Ladies", type: 2, balance: 2000 },
    { name: "Team_Zee", type: 1, balance: 3000000 },
  ]);

  return (
    <>
    <div
      style={{
        width: "100%",
        height:"100%",
        margin: "3rem auto",
        marginTop: "20px",
        backgroundColor: "transparent",
        position: "relative",
        alignItems: "center",
        padding: "5px",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Button
            fullWidth
              style={{
                backgroundImage:
                  "linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)",
                marginBottom: "3rem",
                fontColor:"white",
              }}
              href="/stokvelList"
            >
              Join Stokvel
            </Button>
          </Grid>
        </Grid>
      </Box>

      <MaterialTable
      style={{width:"100%"}}
        title="Current Stokvels "
        icons={tableIcons}
        columns={columns}
        data={data}
        actions={[
          {
            icon: AddBox,
            tooltip: "Add Stokvel",
            isFreeAction: true,
            onClick: handleOpen, //direct to a create stokvel page
          },
          {
            icon: PriceCheckIcon,
            tooltip: "Pay Money",
            onClick: handleOpen, //modal for paying money
          },
          {
            icon: RequestQuoteIcon,
            tooltip: "Request Money",
            onClick: handleOpen,
          },
          {
            icon: InfoIcon,
            tooltip: "Stokvel Info",
            onClick: handleOpen,
          },
        ]}
      />

        <Modal
          keepMounted
          open={open}
          onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Text in a modal
            </Typography>
            <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </Box>
        </Modal>
    </div>
    </>
  );
};
export default Stokvel2;
