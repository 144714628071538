import React from "react";
import Navbar from "../components/Navigation/NavBar";
import StokvelList from "../components/Stokvel/StokvelLists/StokvelList";

const StokvelListPage =()=>{
return(
    <div style={{ backgroundColor: "#cccccc", width: "100%", height: "100vh" }}>
    <Navbar/>
    <StokvelList/>
    </div> 
)
}
export default StokvelListPage;