import React from "react";
import { Typography, Toolbar, AppBar, CssBaseline, Tooltip,Avatar,Container,IconButton } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import DrawerComponent from "./Drawer";
import SavingsIcon from '@mui/icons-material/Savings';
import avatar from "../images/avatar.jpg";
import LogoutIcon from '@mui/icons-material/Logout';

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(2),
    display: "flex",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(2),
    "&:hover": {
      color: "black",
      borderBottom: "1px solid white",
    },
  },
}));

function Navbar() {
  const classes = useStyles();

  return (
    <>
      <MediaQuery minWidth={600}>
        <div style={{padding:"30px"}}>
        <AppBar position="static">
          <Container maxWidth="xl">
          <Toolbar style={{padding:"10px"}} >
            <SavingsIcon fontSize="large" sx={{display:{md:'flex'}, mr:2}}/>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/home"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            STOKVEL
          </Typography>
            <div className={classes.navlinks} style={{marginLeft:"25rem", position:"reletive"}}>
              {/* <Link to="/" className={classes.link}>
                Login
              </Link> 
              <Link to="/home" className={classes.link}>
                Home
              </Link>*/}
              <Link to="/buy" className={classes.link}>
                Buy
              </Link>
              <Link to="/Meet" className={classes.link}>
                Meet
              </Link>
              <Link to="/stokvel" className={classes.link}>
                Stokvel
              </Link>
              <Link to="/transact" className={classes.link}>
                Transact
              </Link>
              <Link to="/wallet" className={classes.link}>
                Wallet
              </Link>
            </div>

            <Tooltip title="Open Profile" style={{marginLeft:"2rem"}}>
              <IconButton href="/profile" sx={{ p: 0}}>
                <Avatar alt="Avatar" src={avatar}/>
              </IconButton>
            </Tooltip>

            <Tooltip title="Logout" style={{marginLeft:"3rem", marginRight:"3rem", color:"white"}} >
              <IconButton href="/" style={{backgroundImage:"#cccccc"}}>
                {<LogoutIcon/>}
              </IconButton>
            </Tooltip>
          </Toolbar>
          </Container>
        </AppBar>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={600}>{<DrawerComponent />}</MediaQuery>
    </>
  );
}
export default Navbar
