import React, { useState } from "react";
import {
  Typography,
  Grid,
  FormControl,
  FormControlLabel,
  Button,
} from "@mui/material";
import classes from "./TsAndCs.module.css";
import { Box } from "@mui/material";
import { Container, width } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import SendSharpIcon from "@mui/icons-material/SendSharp";
import * as Yup from "yup";
import { useFormik } from "formik";

const validationSchema = Yup.object({
  agree: Yup.bool().oneOf([true], "Accept Terms and Conditions")
});

const TsAndCs = ({ handleNext }) => {
  const formik = useFormik({
    initialValues: {
      agree: false
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      handleNext();
    },
  });

  const [state, setState] = useState({
    agree: false,
  });

  const handleCheckboxChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div className={classes.mainContainer}>
      <Typography
        variant="h5"
        style={{
          textAlign: "center",
          color: "black",
          paddingTop: "20px",
          paddingBottom: "15",
        }}
      >
        Terms and Conditions
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <form
            className={classes.formContainer}
            onSubmit={formik.handleSubmit}
          >
            <div
              style={{
                border: "2px solid",
                borderColor: "black",
                marginTop: "2rem",
                overflowY: "scroll",
                fontSize: "13px",
                height: "50vh",
                width: "80vh",
              }}
            >
              <Typography
                style={{
                  textAlign: "justify",
                  spacing: 1.5,
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                In philosophy there is a lot of emphasis on what exists. We call
                this ontology, which means, the study of being. What is less
                often examined is what does not exist. It is understandable that
                we focus on what exists, as its effects are perhaps more
                visible. However, gaps or non-existence can also quite clearly
                have an impact on us in a number of ways. After all, death,
                often dreaded and feared, is merely the lack of existence in
                this world (unless you believe in ghosts). We are affected also
                by living people who are not there, objects that are not in our
                lives, and knowledge we never grasp. Upon further contemplation,
                this seems quite odd and raises many questions. How can things
                that do not exist have such bearing upon our lives? Does nothing
                have a type of existence all of its own? And how do we start our
                inquiry into things we can’t interact with directly because
                they’re not there? When one opens a box, and exclaims “There is
                nothing inside it!”, is that different from a real emptiness or
                nothingness? Why is nothingness such a hard concept for
                philosophy to conceptualize? Let us delve into our proposed box,
                and think inside it a little. When someone opens an empty box,
                they do not literally find it devoid of any sort of being at
                all, since there is still air, light, and possibly dust present.
                So the box is not truly empty. Rather, the word ‘empty’ here is
                used in conjunction with a prior assumption. Boxes were meant to
                hold things, not to just exist on their own. Inside they might
                have a present; an old family relic; a pizza; or maybe even
                another box. Since boxes have this purpose of containing things
                ascribed to them, there is always an expectation there will be
                something in a box. Therefore, this situation of nothingness
                arises from our expectations, or from our being accustomed. The
                same is true of statements such as “There is no one on this
                chair.” But if someone said, “There is no one on this blender”,
                they might get some odd looks. This is because a chair is
                understood as something that holds people, whereas a blender
                most likely not. The same effect of expectation and
                corresponding absence arises with death. We do not often mourn
                people we only might have met; but we do mourn those we have
                known. This pain stems from expecting a presence and having
                none. Even people who have not experienced the presence of
                someone themselves can still feel their absence due to an
                expectation being confounded. Children who lose one or both of
                their parents early in life often feel that lack of being
                through the influence of the culturally usual idea of a family.
                Just as we have cultural notions about the box or chair, there
                is a standard idea of a nuclear family, containing two parents,
                and an absence can be noted even by those who have never known
                their parents. This first type of nothingness I call ‘perceptive
                nothingness’. This nothingness is a negation of expectation:
                expecting something and being denied that expectation by
                reality. It is constructed by the individual human mind,
                frequently through comparison with a socially constructed
                concept.
              </Typography>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.agree}
                    name="agree"
                    id="agree"
                  onChange={handleCheckboxChange}
                  />
                }
                label="Agree to the Terms and Conditions"
              ></FormControlLabel>
            </div>
            <div>
              <Grid item md={6}>
                <item>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    href="/home"
                    style={{ left: "50%" }}
                    endIcon={<SendSharpIcon />}
                  >
                    FINISH
                  </Button>
                </item>
              </Grid>
            </div>
          </form>
        </Grid>
      </Box>
    </div>
  );
};

export default TsAndCs;
