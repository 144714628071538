import React from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Icon,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import classes from './Home.module.css';
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import Banner from "./Banner";
import Carousel from "../Home/Carousel";

const Home2 = () => {
  return (
    <div
     className={classes.containerer}  
    >
      <Carousel/>
     
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button variant="contained" href="/profile" style={{backgroundImage:"linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)", flexDirection:"column",width:"75%"}}>
              {<PersonRoundedIcon fontSize="large"/>}
              <Typography style={{textAlign:"center"}}>Profile</Typography>
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" href="/stokvel" style={{backgroundImage:"linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)", flexDirection:"column" ,width:"75%"}}>
              {<CurrencyExchangeRoundedIcon fontSize="large"/>}
              <Typography style={{textAlign:"center"}}>Stokvel</Typography>
            </Button>
            
          </Grid>
          <Grid item xs={6}>
            <Button  variant="contained" href="/wallet" style={{backgroundImage:"linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)" , flexDirection:"column",width:"75%"}}>
              {<AccountBalanceWalletRoundedIcon fontSize="large" />}
              <Typography style={{textAlign:"center"}}>Wallet</Typography>
            </Button>
            
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              href="/buy"
              style={{backgroundImage:"linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)" , flexDirection:"column",width:"75%"}}
            >
              {<ShoppingCartRoundedIcon fontSize="large"/>}
              <Typography style={{textAlign:"center"}}>Buy</Typography>
            </Button>
            
          </Grid>
         
          <Grid item xs={6}>
            <Button variant="contained" href="/transact"
            style={{backgroundImage:"linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)" , flexDirection:"column",width:"75%"}}>
              {<SyncAltRoundedIcon fontSize="large"/>}
              <Typography style={{textAlign:"center"}}>Transact</Typography>
            </Button>
           
          </Grid>
          <Grid item xs={6}>
            <Button  variant="contained" href="/meet" style={{backgroundImage:"linear-gradient( 95deg,rgb(5,153,64) 0%,rgb(77,209,77) 50%,rgb(70,110,70) 100%)", flexDirection:"column",width:"75%"}}>
              {<GroupsRoundedIcon fontSize="large"/>}
              <Typography style={{textAlign:"center"}}>Meet</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default Home2;
