import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Select,
  MenuItem,
  Modal,
  Link,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import Savings from "./Savings";
import Property from "./Property";
import Grocery from "./Grocery";
import Burial from "./Burial";
import MediaQuery from "react-responsive";
import StokvelList2 from "./StokvelList2";
//import Navbar from "../../Navigation/NavBar";

const StokvelList = () => {
  const [stokvelType, setStokvelType] = useState();

  return (
    <div style={{ backgroundColor: "#cccccc", width: "100%", height: "100vh" }}>
      <MediaQuery minWidth={600}>
      <div
        style={{
          width: "75%",
          //margin: "1rem auto",
          marginTop: "20px",
          backgroundColor: "transparent",
          position: "relative",
          alignItems: "center",
          padding: "25px",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item md={6} lg={6}>
              <FormControl fullWidth>
                <InputLabel>Select Stokvel</InputLabel>
                <Select
                  label="Select Stokvel"
                  labelId="join"
                  variant="outlined"
                  name="join"
                  value={stokvelType}
                  onChange={(event) => setStokvelType(event.target.value)}
                >
                  <MenuItem value={<Savings />}>Savings</MenuItem>
                  <MenuItem value={<Property />}>Property</MenuItem>
                  <MenuItem value={<Grocery />}>Grocery</MenuItem>
                  <MenuItem value={<Burial />}>Burial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>{stokvelType}</div>
      </MediaQuery>
      <MediaQuery maxWidth={600}>
        {<StokvelList2/>}
      </MediaQuery>
    </div>
  );
};
export default StokvelList;
