import { Carousel } from 'react-carousel-minimal';
import React from "react";
import stokvel1 from "../images/stokvel1.jpg";
import stokvel2 from "../images/stokvel2.jpeg";
import stokvel4 from "../images/stokvel4.jpg";
import savings from "../images/savings.jpg";

function ImageCorousel2() {
 const data = [
    {
    image:[stokvel1],
      caption: "Stokvel"
    },
    {
      image: [stokvel2],
      caption: "Invest"
    },
    {
      image: [savings],
      caption: "Property Stokvel"
    },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App" style={{marginBottom:"2rem"}}>

          <Carousel
            data={data}
            time={5000}
            width="100%"
            height="10rem"
            captionStyle={captionStyle}
            radius="10px"
            captionPosition="bottom"
            automatic={true}
            dots={true}
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            style={{
              textAlign: "center",
              maxWidth: "100%",
              maxHeight: "10rem",
              margin: "40px auto",
              marginBottom: "1rem",
              overlay:"black"
            }}
          />
        </div>
  );
}

export default ImageCorousel2;